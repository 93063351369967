import * as React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import { StructuredText } from 'react-datocms';

import Layout from "../components/layout";
import Footer from "../components/footer";

const Policy = ({ data }) => {
    return (
        <Layout>
            <HelmetDatoCms 
                // seo={data.homepage.seoMetaTags}
                bodyAttributes={{
                    class: 'light-bg policy-page'
                }}
            />

            {data.policy.nodes.map(policy => (
                <>
                    <main class="policy">
                        <h1>{policy.title}</h1>
                        <StructuredText data={policy.content} />
                    </main>
                    <Footer color="blue"/>
                </>
            ))}
        </Layout>
    );
}
export default Policy

export const query = graphql`
    query Policy
    (
    $id: String!) 
    {
        policy: allDatoCmsPolicy(filter: {id: {eq: $id}}) {
            nodes {
                title
                slug
                content {
                    blocks
                    links
                    value
                }
            }
        }
}
`;